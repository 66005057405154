// extracted by mini-css-extract-plugin
export var ImageCont = "styles-module--ImageCont--9mrv3";
export var ProjectHeding = "styles-module--ProjectHeding--7KKSF";
export var all = "styles-module--all--BFYcD";
export var cardOuterRow = "styles-module--cardOuterRow--2DwWa";
export var carddiv = "styles-module--carddiv--xMn97";
export var carddiv1 = "styles-module--carddiv1--TbBje";
export var carddiv2 = "styles-module--carddiv2--7a89N";
export var carddiv3 = "styles-module--carddiv3--NY4+7";
export var carddiv4 = "styles-module--carddiv4--0RawB";
export var carddiv5 = "styles-module--carddiv5--9GjUu";
export var carddiv6 = "styles-module--carddiv6--gGeYO";
export var carddiv7 = "styles-module--carddiv7--BZStZ";
export var category = "styles-module--category--Wr3LA";
export var divvv = "styles-module--divvv--2kale";
export var flexdiv = "styles-module--flexdiv--RS3YE";
export var imgcard = "styles-module--imgcard--ac5lh";
export var pbutton = "styles-module--pbutton--hSI+H";
export var primary = "\"abc\"";
export var projectOuterdiv = "styles-module--projectOuterdiv--rXrLE";
export var projectname = "styles-module--projectname--yTcwB";
export var projectpicss = "styles-module--projectpicss--zxGYs";
export var secondary = "\"dec\"";