// extracted by mini-css-extract-plugin
export var MuiOutlinedInputNotchedOutline = "styles-module--MuiOutlinedInput-notchedOutline--3hG0o";
export var MuiSelectSelect = "styles-module--MuiSelect-select--hbjbX";
export var aaa = "styles-module--aaa--WlvJN";
export var antUploadText = "styles-module--antUploadText--aXLgx";
export var css11u53oeMuiSelectSelectMuiInputBaseInputMuiOutlinedInputInput = "styles-module--css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input--tVKAO";
export var cssJedpe8MuiSelectSelectMuiInputBaseInputMuiOutlinedInputInput = "styles-module--css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input--X9sd3";
export var divvv = "styles-module--divvv--aArZg";
export var draggerInbox = "styles-module--draggerInbox--kiOPU";
export var fbutton = "styles-module--fbutton--eSk2s";
export var form_control = "styles-module--form_control--NttY+";
export var form_control1 = "styles-module--form_control1--EokXh";
export var formlabels = "styles-module--formlabels--oiy9j";
export var get = "styles-module--get--yMHEQ";
export var myForm = "styles-module--myForm--2F6Ih";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var setUploadBtn = "styles-module--setUploadBtn--TOxvb";
export var uploadMainDiv = "styles-module--uploadMainDiv--EJNBh";