import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import Box from "@mui/material/Box"
import "./style.scss"
import * as styles from "./styles.module.scss"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import { InboxOutlined, CloudUploadOutlined } from "@ant-design/icons"
import { message, Upload } from "antd"
import { useForm } from "@formspree/react"

const Formpage = () => {
	const { Dragger } = Upload
	const [fileLists, setFileLists] = useState([])
	const companyTypes = [
		{
			value: "Startup_Early Stage",
			label: "Startup Early Stage",
		},
		{
			value: "Startup_Late Stage",
			label: "Startup Late Stage",
		},
		{
			value: "Enterprise",
			label: "Enterprise",
		},
		{
			value: "Non-profit",
			label: "Non-profit",
		},
		{
			value: "Others",
			label: "Others",
		},
	]

	const propss = {
		name: "file",
		maxCount: 1,
		action: "",
		onChange(info) {
			const { status } = info.file
			if (status !== "uploading") {
			}
			if (status === "done") {
				message.success(`${info.file.name} file uploaded successfully.`)
			} else if (status === "error") {
				message.error(`${info.file.name} file upload failed.`)
			}
		},
		beforeUpload: (file) => {
			setFileLists([file])
			return false
		},

		// fileLists,
		// onDrop(e) {},
	}
	const [state, handleSubmit] = useForm("mbjeybbb")
	if (state.succeeded) {
		window.location.reload()
	}

	return (
		<>
			<Container className={`${styles.myForm} ${styles.myForm1}`}>
				<Row>
					<Col>
						<h2 className={styles.get}>Get In Touch</h2>
					</Col>
				</Row>
				<Row>
					<Form onSubmit={handleSubmit}>
						<Row className="m-5" xs={1} md={2}>
							<Box sx={{ minWidth: 100 }} style={{ width: "100%" }}>
								<FormControl fullWidth>
									<Row>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												// ref={fileInput}
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														First Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												name="FirstName"
												fullWidth={true}
												inputProps={{
													className: "inputProps",
												}}
												InputLabelProps={{ className: "inputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														Last Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												name="lastName"
												fullWidth={true}
												inputProps={{ className: "inputProps" }}
												InputLabelProps={{ className: "inputLabelProps" }}
												sx={{
													className: " MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="email"
												name="email"
												fullWidth={true}
												inputProps={{ className: "inputProps" }}
												InputLabelProps={{ className: "inputLabelProps" }}
												sx={{
													className: " MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														Phone No&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												name="phNo"
												fullWidth={true}
												inputProps={{ className: "inputProps" }}
												InputLabelProps={{ className: "inputLabelProps" }}
												sx={{
													className: " MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>

										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												className="aaa"
												id="outlined-select-currency"
												size="small"
												select
												label="Company Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
												defaultValue=""
												fullWidth={true}
												name="CompanyType"
												inputProps={{
													style: {
														fontSize: 13,
														fontFamily: "Proxima Soft",
														height: 20,
														paddingBottom: "10px !important",
													},
												}}
												sx={{
													className: " MuiOutlinedInput-notchedOutline",
												}}
												SelectProps={{ MenuProps: { disableScrollLock: true } }}
												InputLabelProps={{ className: "inputLabelProps" }}
											>
												{companyTypes.map((company) => (
													<MenuItem
														className="aaa"
														key={company.value}
														value={company.value}
														style={{
															fontSize: 16,
															fontFamily: "Proxima Soft",
															height: 20,
														}}
														InputLabelProps={{
															style: { fontSize: 13, color: "red", "&.Mui-selected": { color: "#green" } },
														}}
													>
														{company.label}
													</MenuItem>
												))}
											</TextField>
										</Col>
										<Col md={6} className="py-4 px-md-5 py-md-4">
											<TextField
												id="outlined-basic"
												size="small"
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														Company&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="no"
												name="Company"
												fullWidth={true}
												inputProps={{ className: "inputProps" }}
												InputLabelProps={{ className: "inputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>

										<Col md={12} className="py-4 px-md-5 py-md-4">
											<TextField
												id="standard-multiline-static"
												rows={6}
												variant="outlined"
												label={
													<span style={{ fontFamily: "Proxima Soft" }}>
														Message&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												type="text"
												name="Message"
												multiline
												fullWidth={true}
												inputProps={{ className: "inputMessageProps" }}
												InputLabelProps={{ className: "inputLabelProps" }}
												sx={{
													className: "MuiOutlinedInput-notchedOutline",
												}}
											/>
										</Col>
										{/* <Col md={12} className="py-4 px-md-5 py-md-4">
											<div className={styles.uploadMainDiv}>
												<Dragger {...propss} name="dragger">
													<p className="ant-upload-drag-icon">
														<CloudUploadOutlined className={styles.draggerInbox} />
													</p>
													<p className={styles.antUploadText}>Click or drag file to this area to upload</p>
												</Dragger>
											</div>
										</Col> */}
									</Row>
								</FormControl>
							</Box>
						</Row>

						<div className={styles.divvv}>
							<button className={styles.fbutton} variant="primary" type="submit" disabled={state.submitting}>
								SEND MESSAGES
							</button>
						</div>
					</Form>
				</Row>
			</Container>
		</>
	)
}
export default Formpage
