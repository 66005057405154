import React from "react"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import Projectslide from "components/pages/Project"
import Formpage from "components/pages/Form"
import fonts from "../assets/fonts/Proxima/stylesheet.css"
import { Helmet } from "react-helmet"
const Projectpage = () => {
	return (
		<>
			<Helmet>
				<title>Refstal Solutions | Projects </title>
			</Helmet>
			<HeaderTwo />

			<Projectslide />
			<Formpage className="mt-3 " />
			<Footer />
		</>
	)
}

export default Projectpage
