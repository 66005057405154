import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { navigate } from "gatsby"
import * as styles from "./styles.module.scss"
import AOS from "aos"
import "aos/dist/aos.css"
import { ProjectsPage } from "../../../constants/index"
import { Button } from "antd"

const Projectslide = () => {
	const projectslists = ProjectsPage.projectdetails
	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	// const [age, setAge] = useState("")

	// const handleChange = (event) => {
	// 	setAge(event.target.value)
	// }
	let selectColor = ""
	function viewProjectHandler(e) {
		selectColor = indexesArray[e]

		if (typeof window !== "undefined") {
			localStorage.setItem("activePage", 0)
			localStorage.setItem("selectColor", selectColor)
		}
		navigate("/projectName")
	}

	const colorsArray = ["#6497B1", "#7259D4", "#FF8881", "#29A8AB", "#8874A3", "#E29E4E"]
	const colorsArray1 = ["#6497B1", "#7259D4", "#FF8881"]
	const colorsArray2 = ["#29A8AB", "#8874A3", "#E29E4E"]
	// const colorsArrayCopy = ["#6497B1", "#7259D4", "#FF8881", "#29A8AB", "#8874A3", "#E29E4E"]
	const indexesArray = []
	const checkExistInLast = (clr, index) => {
		let till = indexesArray - index
		let found = false
		if (indexesArray.length > 0) {
			for (let i = 0; index < till; i++) {
				if (colorsArray[i] === clr) {
					found = true
				}
			}
		}
		return found
	}
	const returnColor = (index) => {
		let clr = Math.floor(Math.random() * 3)
		let newClr
		if (index > 3) {
			if (indexesArray.includes(colorsArray2[clr])) {
				colorsArray2.every((c) => {
					if (!indexesArray.includes(c)) {
						newClr = c
						return false
					} else {
						return true
					}
				})
				indexesArray.push(newClr)
				return newClr
			} else {
				indexesArray.push(colorsArray2[clr])
				return colorsArray2[clr]
			}
		} else {
			if (indexesArray.includes(colorsArray1[clr])) {
				colorsArray1.every((c) => {
					if (!indexesArray.includes(c)) {
						newClr = c
						return false
					} else {
						return true
					}
				})
				indexesArray.push(newClr)
				return newClr
			} else {
				indexesArray.push(colorsArray1[clr])
				return colorsArray1[clr]
			}
		}
	}
	return (
		<>
			<div className={`mt-5 pt-2 ${styles.projectOuterdiv}`}>
				<Container>
					<Row>
						<Col>
							<h1 className={styles.ProjectHeding}>PROJECTS</h1>
							<div className={styles.flexdiv}>
								<Button className={styles.all}>All</Button>
								<Button className={styles.all}>Web</Button>
								<Button className={styles.all}>Mobile</Button>
								<Button className={styles.all}>Desktop</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className={`${styles.cardOuterRow} ""`}>
				{projectslists.map((projectslist, index) => {
					return (
						// <div className={styles.carddiv} style={{ backgroundColor: `${projectslist.backgroundColor}` }}>
						<div key={index} className={styles.carddiv} style={{ backgroundColor: returnColor(index + 1) }}>
							<p
								className={styles.category}
								data-aos="fade-up"
								data-aos-once="true"
								data-aos-offset="100"
								data-aos-easing="ease-in-out"
								data-aos-duration="1000"
								data-aos-mirror="true"
							>
								{projectslist.category}
							</p>
							<h3
								className={styles.projectname}
								data-aos="fade-up"
								data-aos-once="true"
								data-aos-offset="100"
								data-aos-easing="ease-in-out"
								data-aos-duration="1000"
								data-aos-mirror="true"
							>
								{projectslist.name}
							</h3>
							<div
								className={styles.divvv}
								data-aos="fade-up"
								data-aos-once="true"
								data-aos-offset="100"
								data-aos-easing="ease-in-out"
								data-aos-duration="1000"
								data-aos-mirror="true"
							>
								<button
									onClick={() => viewProjectHandler(index)}
									className={styles.pbutton}
									variant="primary"
									type="submit"
								>
									{projectslist.btn}
								</button>
								<div className={styles.ImageCont}>
									{/* {projectslist.image} */}
									<StaticImage
										className={styles.projectpicss}
										// src="../../../assets/images/desktop1.png"
										src="../../../assets/images/projects.png"
										height={300}
										alt="desktop1 img"
										placeholder="none"
									/>
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</>
	)
}
export default Projectslide
